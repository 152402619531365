import mailUsSvgTemplate from './templates/mail-us.svg.html';
import callUsSvgTemplate from './templates/call-us.svg.html';
import emailUsSvgTemplate from './templates/email-us.svg.html';
// @ngInject
function AcpContactSectionCtrl(
  $scope,
  $rootScope,
  $window,
  acpAnalytics,
  acpUserModel,
  acpAuthBlocksModel,
  acpBrandingModel,
  $state,
  acpAuthModel,
  $sce,
  acpConfigurationService,
  nsConfig
) {
  $scope.loggedIn = !!acpUserModel.getUser();
  $scope.permissions = {};
  $scope.hasAuthBlocks = acpAuthBlocksModel.has();
  $scope.programType = acpConfigurationService.getProgramType();
  $scope.tenantBrand = acpConfigurationService.getBrand();
  $scope.contactRecipient = nsConfig.get('contactRecipient');

  $scope.actions = {
    clickedEmail: 'clicked email',
    clickedPhone: 'clicked phone'
  };

  var gtmValues = {
    categories: {
      contactUsPreLogin: 'contactUsPreLogin',
      contactUsPostLogin: 'contactUsPostLogin'
    },
    labels: {
      acpPreLoginContact: 'acp-pre-login-contact',
      acpPostLoginContact: 'acp-post-login-contact'
    }
  };

  // We need to have the SVG's inlined so we can style them per brand
  $scope.emailImage = $sce.trustAsHtml(emailUsSvgTemplate);
  $scope.callImage = $sce.trustAsHtml(callUsSvgTemplate);
  $scope.mailImage = $sce.trustAsHtml(mailUsSvgTemplate);

  $scope.login = function () {
    $state.go('login');

    // Make sure we are clean before going back to login
    acpAuthModel.logout();
  };

  $scope.splitEmail = function (email) {
    if (email.length > 30) {
      var temp = email.split('@');

      return $sce.trustAsHtml(temp[0] + '@<br>' + temp[1]);
    }
    return email;
  };

  $scope.$tiePermissions('permissions', [
    'isPasswordAuthed',
    'canCallCustomerService',
    'hasCustomerInbox',
    'isDynamicFaqEnabled',
    'isBDDA',
    'isContactMfeEligible'
  ]);

  $scope.branding = acpBrandingModel.getBranding();

  $scope.track = function (action) {
    var label =
      $scope.loggedIn === true
        ? gtmValues.labels.acpPostLoginContact
        : gtmValues.labels.acpPreLoginContact;
    var category =
      $scope.loggedIn === true
        ? gtmValues.categories.contactUsPostLogin
        : gtmValues.categories.contactUsPreLogin;
    acpAnalytics.sendEvent(category, action, label, null);
  };

  $scope.deviceInfo = '';
  if ($window.device && !$rootScope.acpDeviceService.isWeb()) {
    $scope.deviceInfo =
      '\n\nModel: ' +
      $window.device.model +
      ' Platform: ' +
      $window.device.platform +
      ' Version: ' +
      $window.device.version +
      ' Auth: ' +
      ($scope.permissions.isPasswordAuthed ? 'Y' : 'N');
  }

  $scope.hideReloadLinks = function () {
    var brandsWithoutReloadLocation = [
      'albertsons_gpr_republic',
      'brinks_money',
      'netspendExpense'
    ];
    return brandsWithoutReloadLocation.indexOf($scope.tenantBrand) !== -1;
  };
}

export default AcpContactSectionCtrl;
