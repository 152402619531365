import contactContent from './contact-content.yml';
import acpContactCtrl from './acp-contact-ctrl';
import acpComponentUnauthedLayout from 'components/unauthed-layout';
import acpCore from 'core';
import ng from 'angular';
import './styles/${theme}/core.scss';
var section = ng
  .module('acp.apps.contact-us', [
    acpCore.name,
    acpComponentUnauthedLayout.name
  ])
  .controller('AcpContactSectionCtrl', acpContactCtrl)
  .run(
    /*@ngInject*/ function(contentSectionCache) {
      contentSectionCache.put('apps/contact-us', contactContent);
    }
  );

import templates from './templates';
templates(section);

export default section;
export { default as acpContactUsContent } from './contact-content.yml';
